import React from "react";

const InvestmentSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 496 496"
      className="sidebarIcons"
    >
      <path d="M424,248c0-97.048-78.952-176-176-176S72,150.952,72,248s78.952,176,176,176S424,345.048,424,248z M248.008,408     c-16.728,0-32.848-2.6-48.008-7.376V304h-16v90.552c-8.4-3.68-16.424-8.048-24-13.064V304c0-22.056,17.944-40,40-40h96     c22.056,0,40,17.944,40,40h-51.056l-13.792-27.576l-14.312,7.16L267.056,304H216v16h16v8l6.12,43.4     c1.68,11.744,11.888,20.6,23.76,20.6h55.408C296.288,402.144,272.848,408,248.008,408z M184,184c0-35.288,28.712-64,64-64     c35.288,0,64,28.712,64,64c0,35.288-28.712,64-64,64C212.712,248,184,219.288,184,184z M344,327.432l-5.96,41.704     c-0.552,3.912-3.96,6.864-7.912,6.864H261.88c-3.952,0-7.36-2.952-7.912-6.864L248,327.432V320h27.056l13.792,27.576l14.312-7.16     L292.944,320H344V327.432z M354.504,367.072l5.424-37.936L359.96,320H376v-16h-24c0-30.872-25.128-56-56-56h-0.208     C315.296,233.4,328,210.184,328,184c0-44.112-35.888-80-80-80s-80,35.888-80,80c0,26.184,12.704,49.4,32.208,64H200     c-30.872,0-56,25.128-56,56v65.344C109.784,339.976,88,296.52,88,248c0-88.224,71.776-160,160-160s160,71.776,160,160     C408,295.304,387.248,337.752,354.504,367.072z" />
      <path d="M455.824,240.928l-11.384-7.584l-8.872,13.312l29.456,19.64l28.64-28.64l-11.312-11.312l-10.624,10.624     C465.952,118.56,367.816,24,248,24v16C360.328,40,452.072,129.504,455.824,240.928z" />
      <path d="M40.176,255.072l11.384,7.584l8.872-13.312l-29.456-19.64l-28.64,28.64l11.312,11.312l10.624-10.624     C30.048,377.44,128.184,472,248,472v-16C135.672,456,43.928,366.496,40.176,255.072z" />
      <path d="M64,128c35.288,0,64-28.712,64-64S99.288,0,64,0S0,28.712,0,64C0,99.288,28.712,128,64,128z M64,16     c26.472,0,48,21.528,48,48s-21.528,48-48,48S16,90.472,16,64S37.528,16,64,16z" />
      <path d="M56,95.592V104h16v-8.408C81.12,93.736,88,85.656,88,76c0-11.032-8.968-20-20-20h-8c-2.2,0-4-1.8-4-4s1.8-4,4-4h8     c2.2,0,4,1.8,4,4v4h16v-4c0-9.664-6.88-17.736-16-19.592V24H56v8.408C46.88,34.264,40,42.336,40,52c0,11.032,8.968,20,20,20h8     c2.2,0,4,1.8,4,4s-1.8,4-4,4h-8c-2.2,0-4-1.8-4-4v-4H40v4C40,85.664,46.88,93.736,56,95.592z" />
      <path d="M432,368c-35.288,0-64,28.712-64,64c0,35.288,28.712,64,64,64c35.288,0,64-28.712,64-64C496,396.712,467.288,368,432,368     z M432,480c-26.472,0-48-21.528-48-48s21.528-48,48-48s48,21.528,48,48S458.472,480,432,480z" />
      <path d="M440,400.408V392h-16v8.408c-9.12,1.856-16,9.936-16,19.592c0,11.032,8.968,20,20,20h8c2.2,0,4,1.8,4,4s-1.8,4-4,4h-8     c-2.2,0-4-1.8-4-4v-4h-16v4c0,9.664,6.88,17.736,16,19.592V472h16v-8.408c9.12-1.856,16-9.936,16-19.592c0-11.032-8.968-20-20-20     h-8c-2.2,0-4-1.8-4-4s1.8-4,4-4h8c2.2,0,4,1.8,4,4v4h16v-4C456,410.336,449.12,402.264,440,400.408z" />
    </svg>
  );
};

export default InvestmentSvg;
