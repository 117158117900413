import React, { useState } from "react";
import { Input } from "../Input/Input";
import { useTranslation } from "react-i18next";
import "./FilterBox.css";

export const FilterBox = ({
  searchLabel,
  tableFilterData,
  setTableFilterOutcomingData,
  tableFilterOutcomingData,
  tableHeader,
  tableSearchSelect,
  customStyles,
  searchInputValue,
}) => {
  const [headActive, setHeadActive] = useState(
    tableFilterOutcomingData?.selects?.statuses || "all"
  );
  const { t } = useTranslation();

  const handleSelectChange = (option, name) => {
    setTableFilterOutcomingData((prev) => ({
      ...prev,
      selects: {
        ...prev.selects,
        [name]: option,
      },
    }));
  };

  const handleSearchChange = (e) => {
    setTableFilterOutcomingData((prev) => ({
      ...prev,
      search: {
        value: e.target.value,
        option: prev?.search?.option || null,
      },
    }));
  };

  const handleSearchSelect = (option) => {
    setTableFilterOutcomingData((prev) => ({
      ...prev,
      search: {
        ...prev.search,
        option,
      },
    }));
  };

  const headerList = tableHeader && tableFilterData.selects[tableHeader];

  return (
    <div className="filter-box-container" style={customStyles}>
      {headerList && (
        <ul className="filter-box-list font-14">
          <li
            key="all"
            className={`filter-box-list__item ${
              headActive === "all" && "list-item__active"
            }`}
            onClick={() => {
              handleSelectChange("all", headerList.value);
              setHeadActive("all");
            }}
          >
            {t("all")}
          </li>
          {headerList.options.map((item) => (
            <li
              key={item.name}
              className={`filter-box-list__item ${
                headActive === item.value && "list-item__active"
              }`}
              onClick={() => {
                handleSelectChange(item.value, headerList.value);
                setHeadActive(item.value);
              }}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
      <div className="filter-box-input">
        <Input
          type="search-input"
          onChange={handleSearchChange}
          defaultData={tableFilterData?.search?.options || []}
          selectHandler={handleSearchSelect}
          placeholder={searchLabel || t("Search")}
          selectLabel={t("All")}
          select={tableSearchSelect}
        />
      </div>
    </div>
  );
};
